
import React from 'react';
import Footer from '..//Footer'
import Header from '..//Header'
import ImageGallery from '..//ImageGallery'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'
import { SegmentedControl } from 'segmented-control'

class Engineering extends React.Component {
  constructor() {
    super();

    this.state = {
      routerValue: Router,
      imageList: ["Engineering-Calculator1","Engineering-Calculator2","Engineering-Calculator3","Engineering-Calculator4","Engineering-Calculator5","Engineering-Calculator6"],
      OSName: 'iOS/'
    };
  }

  OSon() {
    this.setState({ OSName: 'iOS/' })
  }

  iPadOSon() {
    this.setState({ OSName: 'iPadOS/' })
  }

  selectOS(value) {
    if (value === "ios") {
      this.setState({ OSName: 'iOS/' })
    } else if (value === "ipados") {
      this.setState({ OSName: 'iPadOS/' })
    }
  }

  render() {
    var OSSelectionButton = [];
    if (this.state.OSName === 'iOS/') {
      OSSelectionButton.push(
        <div className="Toggle-Div">
          <button className="ToggleOS" onClick={() => {this.iPadOSon()}}>View iPadOS</button>
        </div>
      )
    } else if (this.state.OSName === 'iPadOS/') {
      OSSelectionButton.push(
        <div className="Toggle-Div">
          <button className="ToggleOS" onClick={() => {this.watchOSon()}}>View watchOS</button>
        </div>
      ) 
      } else {
        OSSelectionButton.push(
          <div className="Toggle-Div">
            <button className="ToggleOS" onClick={() => {this.iOSon()}}>View iOS</button>
          </div>
        )
    }
    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside-Clear">
          <div className="App-Page-Top">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Engineering-Calculator.png'} alt=""/>
            <p className="App-Page-Title">Engineering Calculator</p>
          </div>
          <a className="Download-Icon-Link" href="https://itunes.apple.com/app/id1297957037?mt=12">
            <img className="Download-Icon" src={process.env.PUBLIC_URL + '/DownloadBadges/Download_iOS-Black.png'} alt=""/>
          </a>
          <br></br>
          <br></br>
          <div className="Toggle-Div">
            <SegmentedControl
            name="osSegment"
            className="osSegment"
            options={[
              { label: "iOS", value: "ios", default: true },
              { label: "iPadOS", value: "ipados" }
            ]}
            setValue={newValue => this.selectOS(newValue)}
            style={{width: 250, color: '#303030'}}
            />
          </div>
          <ImageGallery
          imageList = {this.state.imageList}
          OS = {this.state.OSName}
          folder = {"Engineering-Calculator/"}
          />
          <div className="App-Description">
            <p>Engineering Calculator is an easy to use App that Contains 60 Engineering Calculators Split into 3 Categories:</p>
            <br></br>
            <p>Civil Engineering Includes 29 Calculators:</p>
            <ul>
              <li>Cantilever Beam</li>
              <li>Pipeline Flow Rate</li>
              <li>Cantilever Beam Slope and Deflection With Couple Moment</li>
              <li>Cantilever Beam Slope and Deflection with Uniformly Distributed Load</li>
              <li>Cantilever Beam Slope and Deflection Calculator For Uniform Load</li>
              <li>Cantilever Beam Slope and Deflection Calculator for Load at Free End</li>
              <li>Cantilever Beam Slope and Deflection Calculator for Load at Any Point</li>
              <li>Feet (ft) and Inches (in) Arithmetic Calculator</li>
              <li>Flexible Pavement Structural Number</li>
              <li>Vertical Curve Offset Distance</li>
              <li>Rate of Change of Grade on Roadways</li>
              <li>Transportation Highways Horizontal Curve</li>
              <li>Elevation Point of Vertical Curve on Road</li>
              <li>Vehicle Speed Stopping Distance</li>
              <li>Spiral / Transition Curve Tangent Distance</li>
              <li>Spiral / Transition Curve Deflection Angle</li>
              <li>Earthwork Cross Section Volume</li>
              <li>Concrete Slab Maximum Length</li>
              <li>Concrete Slab Pour Volume</li>
              <li>Concrete Slab Maximum Wall Load</li>
              <li>Maximum Floor Live Load Capacity</li>
              <li>Concrete Block Footing Volume</li>
              <li>Cubic Yards</li>
              <li>Concrete Footing / Slab Pours</li>
              <li>Concrete Volume</li>
              <li>Block Wall</li>
              <li>Concrete Material Calculator Circle</li>
              <li>Concrete Material Calculator Rectangle</li>
              <li>Concrete Material Calculator Triangle</li>
            </ul>
            <br></br>
            <p>Electrical Engineering 18 Calculators:</p>
            <ul>
              <li>Cable Length from Sag, Span</li>
              <li>Spring Resonant Frequency</li>
              <li>Solenoid Coil Electromagnetic Force</li>
              <li>Capacitor Energy (E) and RC Time Constant</li>
              <li>Physical Properties of Coil / Material</li>
              <li>Inductance of an Air Core Coil</li>
              <li>Parallel Resistor</li>
              <li>Straight Wire Inductor</li>
              <li>8051 PIC Microcontroller Time Delay</li>
              <li>Microstrip Transmission Lines Impedance</li>
              <li>Electrical Admittance (Y)</li>
              <li>Electrical Power Factor</li>
              <li>Specific Work of Gas Turbine</li>
              <li>3 Phase Power Calculator by Two Wattmeters Method</li>
              <li>Specific Work</li>
              <li>Youngs Modulus Spring Resonant Frequency</li>
              <li>Gear Ratio</li>
              <li>mA/h Power Consumption</li>
            </ul>
            <br></br>
            <p>Mechanical Engineering Includes 12 Calculators:</p>
            <ul>
              <li>Push / Pull Hydraulic Cylinder</li>
              <li>Square Tube</li>
              <li>Lathe Boring Time</li>
              <li>Lathe Drilling Time</li>
              <li>Lathe Cutting / Tapping Time</li>
              <li>Lathe Turning Time</li>
              <li>Screw Thread Shear</li>
              <li>Shear Area of Internal Screw Thread</li>
              <li>Ultimate Tensile Stress</li>
              <li>Maximum Deflection of Beam with Load at Center</li>
              <li>Mohrs Circle</li>
              <li>Turning Surface Roughness</li>
            </ul>
            <br></br>
            <p>Engineering Calculator also includes:</p>
            <ul>
              <li>Formula for Each Calculator</li>
              <li>Description for Each Calculator</li>
              <li>Copy Answer Button</li>
              <li>Clear Button</li>
            </ul>
          </div>
          <br></br>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default withRouter(Engineering);
