
import React from 'react';
import Footer from '..//Footer'
import Header from '..//Header'
import ImageGallery from '..//ImageGallery'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'
import { SegmentedControl } from 'segmented-control'

class Geometry extends React.Component {
  constructor() {
    super();

    this.state = {
      routerValue: Router,
      imageList: ["Geometry-Calculator1","Geometry-Calculator2","Geometry-Calculator3","Geometry-Calculator4","Geometry-Calculator5","Geometry-Calculator6"],
      OSName: 'iOS/'
    };
  }

  OSon() {
    this.setState({ OSName: 'iOS/' })
  }

  iPadOSon() {
    this.setState({ OSName: 'iPadOS/' })
  }

  selectOS(value) {
    if (value === "ios") {
      this.setState({ OSName: 'iOS/' })
    } else if (value === "ipados") {
      this.setState({ OSName: 'iPadOS/' })
    }
  }

  render() {
    var OSSelectionButton = [];
    if (this.state.OSName === 'iOS/') {
      OSSelectionButton.push(
        <div className="Toggle-Div">
          <button className="ToggleOS" onClick={() => {this.iPadOSon()}}>View iPadOS</button>
        </div>
      )
    } else if (this.state.OSName === 'iPadOS/') {
      OSSelectionButton.push(
        <div className="Toggle-Div">
          <button className="ToggleOS" onClick={() => {this.watchOSon()}}>View watchOS</button>
        </div>
      ) 
      } else {
        OSSelectionButton.push(
          <div className="Toggle-Div">
            <button className="ToggleOS" onClick={() => {this.iOSon()}}>View iOS</button>
          </div>
        )
    }
    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside-Clear">
          <div className="App-Page-Top">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Geometry-Calculator.png'} alt=""/>
            <p className="App-Page-Title">Geometry Calculator</p>
          </div>
          <a className="Download-Icon-Link" href="https://itunes.apple.com/app/id1286515380?mt=12">
            <img className="Download-Icon" src={process.env.PUBLIC_URL + '/DownloadBadges/Download_iOS-Black.png'} alt=""/>
          </a>
          <br></br>
          <br></br>
          <div className="Toggle-Div">
            <SegmentedControl
            name="osSegment"
            className="osSegment"
            options={[
              { label: "iOS", value: "ios", default: true },
              { label: "iPadOS", value: "ipados" }
            ]}
            setValue={newValue => this.selectOS(newValue)}
            style={{width: 250, color: '#303030'}}
            />
          </div>
          <ImageGallery
          imageList = {this.state.imageList}
          OS = {this.state.OSName}
          folder = {"Geometry-Calculator/"}
          />
          <div className="App-Description">
          <p>Geometry Calculator allows you to calculate the Area, Surface Area, Volume and Perimeter of shapes. All you have to do is enter the required values into the fields.</p>
          <p>Geometry Calculator can calculate the following:</p>
          <br></br>
          <p>Area:</p>
            <ul>
              <li>Circle</li>
              <li>Triangle</li>
              <li>Rectangle</li>
              <li>Ellipse</li>
              <li>Square</li>
              <li>Parallellogram</li>
              <li>Sector</li>
              <li>Trapezium</li>
              <li>Kite</li>
            </ul>
            <br></br>
            <p>Surface Area:</p>
            <ul>
              <li>Cube</li>
              <li>Rectangular Prism</li>
              <li>Sphere</li>
              <li>Cylinder</li>
            </ul>
            <br></br>
            <p>Volume:</p>
            <ul>
              <li>Cube</li>
              <li>Rectangular Prism</li>
              <li>Sphere</li>
              <li>Ellipsoid</li>
              <li>Cylinder</li>
              <li>Cone</li>
              <li>Pyramid</li>
            </ul>
            <br></br>
            <p>Perimeter</p>
            <ul>
              <li>Square</li>
              <li>Rectangle</li>
              <li>Parallellogram</li>
              <li>Triangle</li>
              <li>Polygon</li>
              <li>Trapezoid</li>
              <li>Circle</li>
            </ul>
            <br></br>
            <p>Features</p>
            <ul>
              <li>Formula for each Calculation</li>
              <li>Image of each shape</li>
              <li>Calculate Any Variable in a Formula</li>
              <li>Clear</li>
              <li>Clear All</li>
              <li>Copy Answer</li>
              <li>Copy Solution</li>
            </ul>
            <br></br>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default withRouter(Geometry);
