
import React from 'react';
import Footer from '..//Footer'
import Header from '..//Header'
import ImageGallery from '..//ImageGallery'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'

class Physics extends React.Component {
  constructor() {
    super();

    this.state = {
      routerValue: Router,
      imageList: ["Physics-Calculator1","Physics-Calculator2","Physics-Calculator3","Physics-Calculator4","Physics-Calculator5","Physics-Calculator6"],
    };
  }

  render() {
    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside-Clear">
          <div className="App-Page-Top">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Physics-Calculator.png'} alt=""/>
            <p className="App-Page-Title">Physics Calculator</p>
          </div>
          <a className="Download-Icon-Link" href="https://itunes.apple.com/app/id1104781999?mt=12">
            <img className="Download-Icon" src={process.env.PUBLIC_URL + '/DownloadBadges/Download_macOS-Black.png'} alt=""/>
          </a>
          <ImageGallery
          imageList = {this.state.imageList}
          OS = {"macOS/"}
          folder = {"Physics-Calculator/"}
          />
          <div className="App-Description">
          <p>Physics Calculator is an easy to use App that includes 120 Physics Calculators</p>
            <p>Physics Calculator includes the following Calculators:</p>
            <ul>
            <li>Force</li>
              <li>Work</li>
              <li>Total Work Done</li>
              <li>Power to Work</li>
              <li>Power With Displacement</li>
              <li>Power With Velocity</li>
              <li>Kinetic Energy</li>
              <li>Potential Energy</li>
              <li>Centripetal Acceleration</li>
              <li>Concave Mirror Magnification</li>
              <li>Centripetal Force</li>
              <li>Circular Velocity</li>
              <li>Velocity</li>
              <li>Average Velocity</li>
              <li>Displacement</li>
              <li>Kinetic Friction</li>
              <li>Static Friction</li>
              <li>Newton’s Law of Gravity</li>
              <li>Planetary Motion</li>
              <li>Gravitational Acceleration</li>
              <li>Escape Velocity</li>
              <li>Hooke Law</li>
              <li>Elastic Potential Energy</li>
              <li>Newtons Second Law of Motion</li>
              <li>Impulse With Velocity</li>
              <li>Impulse With Time</li>
              <li>Momentum With Velocity</li>
              <li>Momentum With Time</li>
              <li>Moment</li>
              <li>Simple Pendulum</li>
              <li>Physical Pendulum</li>
              <li>Torque</li>
              <li>Density</li>
              <li>Einstein Mass Energy</li>
              <li>Stress</li>
              <li>Strain</li>
              <li>Youngs Modulus</li>
              <li>Projectile Motion for Vertical Velocity</li>
              <li>Projectile Motion for Vertical Displacement</li>
              <li>Projectile Motion for Horizontal Displacement</li>
              <li>Projectile Motion for Range</li>
              <li>Doppler Effect Wavelength Front</li>
              <li>Doppler Effect Wavelength Behind</li>
              <li>Doppler Effect Approaching Source</li>
              <li>Doppler Effect Receding Source</li>
              <li>Doppler Effect Approaching Receiver</li>
              <li>Doppler Effect Receding Receiver</li>
              <li>Differential Pressure Measurement</li>
              <li>Sound Pressure Level</li>
              <li>Sound Intensity Level</li>
              <li>Sound Power Emitted</li>
              <li>Sound Wavelength</li>
              <li>Free Fall</li>
              <li>Noise Pollution Level</li>
              <li>Battery Storage Rating</li>
              <li>True Porosity</li>
              <li>Souders Brown Equation</li>
              <li>Concave Mirror Equation</li>
              <li>Constant Q Transform</li>
              <li>Horsepower</li>
              <li>Moment of Inertia</li>
              <li>Transverse Strength of a Material</li>
              <li>Torsional Pendulum</li>
              <li>Apparent Porosity</li>
              <li>Kinematic Viscosity</li>
              <li>Speed of Sound</li>
              <li>Lensmaker's Equation</li>
              <li>Podmore Factor</li>
              <li>Critical Frequency</li>
              <li>Brinell Hardness Number</li>
              <li>Voltage Divider Rule</li>
              <li>Electrodialysis</li>
              <li>Amount of Substance</li>
              <li>Angular Acceleration</li>
              <li>Newton's Law of Cooling</li>
              <li>Potentiometer</li>
              <li>Drift Velocity</li>
              <li>Helical Spring Index</li>
              <li>Gravitational Potential Energy</li>
              <li>Sound Decibel Distance</li>
              <li>One Dimensional Motion Velocity</li>
              <li>Acceleration Due to Gravity on Outside Earth</li>
              <li>Boltzmann Gas Constant</li>
              <li>De Broglie Wavelength</li>
              <li>Average Translational Kinetic Energy</li>
              <li>Nature of Light</li>
              <li>Tidal Force</li>
              <li>Average Acceleration</li>
              <li>Kinematic Displacement By Velocity and Time</li>
              <li>Kinematic Distance</li>
              <li>Kinematic Final Velocity</li>
              <li>Kinematic Velocity</li>
              <li>Focal Length of Optical Convex</li>
              <li>Magnitude of Vector</li>
              <li>Moment of Inertia of a Point Mass for Rod</li>
              <li>Work for Linear Motion</li>
              <li>Sound Wave Velocity</li>
              <li>Ceramic Transverse Strength</li>
              <li>Tension of Wire Rope Holding a Load</li>
              <li>Normal Force of an Object at Rest</li>
              <li>Normal Force of an Object on an Inclined Plane</li>
              <li>Normal Force of an Object with External Downward Force</li>
              <li>Normal Force and Kinetic Friction</li>
              <li>Refractive Index Formula</li>
              <li>Solid Shaft Maximum Torque</li>
              <li>Solid Shaft Diameter</li>
              <li>Shear Stress in Shaft</li>
              <li>Lowest Spring Resonant Frequency</li>
              <li>Hollow Shaft Maximum Torque</li>
              <li>Work Done by Frictional Force</li>
              <li>Stokes Law</li>
              <li>Newton's Third Law of Motion</li>
              <li>Axial Stress of Cylinder Wall or Tube</li>
              <li>Circle Center of Gravity</li>
              <li>Inverse Square Law</li>
              <li>Coefficient of Friction</li>
              <li>Torsional Deflection of a Shaft</li>
              <li>Drag Force on Disk</li>
              <li>Vickers Hardness</li>
              <li>Muzzle Velocity</li>
            </ul>
            <br></br>
            <p>It Also Includes:</p>
            <ul>
              <li>Formula for Each Calculator</li>
              <li>Description for Each Calculator</li>
              <li>Copy Answer Button</li>
              <li>Clear Button</li>
              <li>Clear All Button</li>
            </ul>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default withRouter(Physics);
