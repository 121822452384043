
import React from 'react';
import Footer from '..//Footer'
import Header from '..//Header'
import ImageGallery from '..//ImageGallery'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'
import { SegmentedControl } from 'segmented-control'

class FluidMechanics extends React.Component {
  constructor() {
    super();

    this.state = {
      routerValue: Router,
      imageList: ["Fluid-Mechanics-Calculator1","Fluid-Mechanics-Calculator2","Fluid-Mechanics-Calculator3","Fluid-Mechanics-Calculator4","Fluid-Mechanics-Calculator5","Fluid-Mechanics-Calculator6"],
      OSName: 'iOS/'
    };
  }

  OSon() {
    this.setState({ OSName: 'iOS/' })
  }

  iPadOSon() {
    this.setState({ OSName: 'iPadOS/' })
  }

  selectOS(value) {
    if (value === "ios") {
      this.setState({ OSName: 'iOS/' })
    } else if (value === "ipados") {
      this.setState({ OSName: 'iPadOS/' })
    }
  }

  render() {
    var OSSelectionButton = [];
    if (this.state.OSName === 'iOS/') {
      OSSelectionButton.push(
        <div className="Toggle-Div">
          <button className="ToggleOS" onClick={() => {this.iPadOSon()}}>View iPadOS</button>
        </div>
      )
    } else if (this.state.OSName === 'iPadOS/') {
      OSSelectionButton.push(
        <div className="Toggle-Div">
          <button className="ToggleOS" onClick={() => {this.watchOSon()}}>View watchOS</button>
        </div>
      ) 
      } else {
        OSSelectionButton.push(
          <div className="Toggle-Div">
            <button className="ToggleOS" onClick={() => {this.iOSon()}}>View iOS</button>
          </div>
        )
    }
    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside-Clear">
          <div className="App-Page-Top">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Fluid-Mechanics-Calculator.png'} alt=""/>
            <p className="App-Page-Title">Fluid Mechanics Calculator</p>
          </div>
          <a className="Download-Icon-Link" href="https://itunes.apple.com/app/id1297964597?mt=12">
            <img className="Download-Icon" src={process.env.PUBLIC_URL + '/DownloadBadges/Download_iOS-Black.png'} alt=""/>
          </a>
          <br></br>
          <br></br>
          <div className="Toggle-Div">
            <SegmentedControl
            name="osSegment"
            className="osSegment"
            options={[
              { label: "iOS", value: "ios", default: true },
              { label: "iPadOS", value: "ipados" }
            ]}
            setValue={newValue => this.selectOS(newValue)}
            style={{width: 250, color: '#303030'}}
            />
          </div>
          <ImageGallery
          imageList = {this.state.imageList}
          OS = {this.state.OSName}
          folder = {"Fluid-Mechanics-Calculator/"}
          />
          <div className="App-Description">
            <p>Fluid Mechanics Calculator is an easy to use App that Contains 90 Calculators</p>
            <p>Fluid Mechanics Calculator includes the following Calculators:</p>
            <ul>
              <li>Dry Bulk Density of Soil</li>
              <li>Specific Gravity with Water Weight</li>
              <li>Broad Crested Weir Flow Rate</li>
              <li>Rectangular Weir Flow Rate</li>
              <li>Specific Gravity of Water</li>
              <li>Bernoulli Equation with Head Loss</li>
              <li>Flow Rate</li>
              <li>Darcy Weisbach - Head Loss</li>
              <li>Fluid Pressure</li>
              <li>Absolute Pressure</li>
              <li>Bulk Modulus</li>
              <li>Compressibility</li>
              <li>Fluid Density with Pressure</li>
              <li>Mean Fluid Velocity - Hazen Williams</li>
              <li>Fluid Flow Rate - Hazen Williams</li>
              <li>Manning Flow Velocity</li>
              <li>Minor Losses</li>
              <li>Water Horsepower</li>
              <li>Pump Efficiency</li>
              <li>Net Positive Suction Head and Cavitation</li>
              <li>Stokes Law</li>
              <li>Venturi Meter Flow Rate</li>
              <li>Pressure Rating - Aluminum Pipe</li>
              <li>Pressure - Buried Corrugated Metal Pipe Thrust</li>
              <li>Pipe Wall - Buried Corrugated Metal Pipe Thrust</li>
              <li>Cross Sectional Area - Buried Corrugated Metal Pipe Thrust</li>
              <li>Chezy Flow Velocity</li>
              <li>Chezy Coefficient</li>
              <li>Darcys Law - Flow Rate, Hydraulic Conductivity, Gradient, Flow Cross Sectional Area</li>
              <li>Darcy's Law - Hydraulic Gradient, Pressure Head, Column Length</li>
              <li>Darcy's Law - Seepage Velocity, Darcy Flux, Flow and Voids Cross Sectional Area</li>
              <li>Darcy's Law - Flux, Hydraulic Conductivity and Gradient</li>
              <li>Darcy's Law - Seepage Velocity, Darcy Flux, Porosity</li>
              <li>Darcy's Law - Void Ratio, Voids and Solids Volume</li>
              <li>Darcy's Law - Porosity, Voids and Total Volume</li>
              <li>Darcy's Law - Saturated Soil, Voids, Solids and Total Volume</li>
              <li>Wall Thickness - Ductile Iron Pipe</li>
              <li>Ductile Iron Pipe - Internal, Working, Maximum Surge Pressure</li>
              <li>French Drain Seepage Rate, Permeability, Flow Line Slope</li>
              <li>Hydraulic Radius</li>
              <li>Orifice Flow Rate</li>
              <li>Parshall Flume Flow Rate</li>
              <li>Permeameter Porous Medium Flow Rate</li>
              <li>External Hydrostatic Pressure</li>
              <li>Pipe Soil Weight Pressure</li>
              <li>Pipe Water Buoyancy Factor</li>
              <li>Soil Load Per Linear Length Pipe</li>
              <li>Pipe Vacuum Pressure Load</li>
              <li>Plastic Pipe - Outside Diameter Controlled</li>
              <li>Plastic Pipe - Inside Diameter Controlled</li>
              <li>Plastic Pipe - AWWA C900 Pressure Class</li>
              <li>Plastic Pipe - Short Term Pressure Rating</li>
              <li>Plastic Pipe - Outside Diameter Controlled Short Term Strength</li>
              <li>Smooth Wall Steel Pipe - Pressure Rating, Stress, Thickness</li>
              <li>Specific Gas Constant, Molecular Weight</li>
              <li>Specific Volume, Density</li>
              <li>Area of Thrust Block, Thrust Force, Soil Bearing Pressure</li>
              <li>Unconfined Aquifer Well Design - Flow Rate</li>
              <li>Unrestrained Pipe Length Change</li>
              <li>Restrained Anchored Pipe Stress</li>
              <li>Water Hammer - Maximum Surge Pressure Head</li>
              <li>Water Hammer - Maximum Surge Pressure of Fluid</li>
              <li>Water Hammer - Maximum Surge Pressure of Water</li>
              <li>Water Hammer - Pressure Increase, Flow Velocity, Pipe Length</li>
              <li>Acoustic Flowmeter</li>
              <li>Curb Gutter Flow Rate</li>
              <li>Curb Capture Flow Rate</li>
              <li>Gutter Interception Capacity</li>
              <li>Slotted Pipe Gutter Interception</li>
              <li>Gutter Capture Efficiency</li>
              <li>Gutter Carryover</li>
              <li>Reynolds Number</li>
              <li>Cauchy Number</li>
              <li>Cavitation Number</li>
              <li>Eckert Number</li>
              <li>Euler Number</li>
              <li>Froude Number</li>
              <li>Knudsen Number</li>
              <li>Lewis Number</li>
              <li>Mach Number</li>
              <li>Nusselt Number</li>
              <li>Peclet Number</li>
              <li>Prandtl Number</li>
              <li>Schmidt Number</li>
              <li>Sherwood Number</li>
              <li>Threshold Odor, Water Odor Causes, Odor Free Water</li>
              <li>Weber Number - Density, Velocity, Surface Tension</li>
              <li>Fourier Number, Thermal Diffusivity, Characteristic Time, Length</li>
              <li>Static Failure Analysis of Brittle Material</li>
              <li>Strouhal Number - Fluid Flow Dynamics</li>
            </ul>
            <br></br>
            <p>It Also Includes:</p>
            <ul>
              <li>Formula for Each Calculator</li>
              <li>Description for Each Calculator</li>
              <li>Copy Button</li>
              <li>Clear Button</li>
            </ul>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default withRouter(FluidMechanics);
