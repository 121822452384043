/*THis componenet renders vanilla header with logo and links 

Drop down menu for small screen devices uses this example
https://blog.campvanilla.com/reactjs-dropdown-menus-b6e06ae3a8fe
and is modified
*/

import React from 'react';
import Footer from './Footer'
import Header from './Header'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'

class MacOSApps extends React.Component {
  constructor() {
    super();

    this.state = {
      showMenu: false,
      routerValue : Router,
    };
  }

  render() {
    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside">
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/CalcVerter.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">CalcVerter for macOS</p>
              <ul className="App-Features">
                <li>22 Unit Conversion Categories</li>
                <li>Scientific-Calculator with optional RPN mode</li>
                <li>30 Geometry Calculators</li>
                <li>97 Finance Calculators</li>
                <li>120 Physics Calculators</li>
                <li>34 Health Calculators</li>
                <li>59 Engineering Calculators</li>
                <li>78 Dynamics Calculators</li>
                <li>35 Chemistry Calculators</li>
                <li>90 Fluid Mechanics Calculators</li>
                <li>5 Quantum Calculators</li>
                <li>Base Converter Converts between bases 2 - 64</li>
                <li>Weight on other Planets and Celestial Objects Calculator</li>
                <li>Periodic Table Of Elements</li>
                <li>Create, share and import user calculators</li>
                <li>Create, share and import user constants</li>
              </ul> 
              <div className="App-Links">
              <a className="App-Link" href="https://loaysetrak.github.io/#macos/calcverter">About Version 6.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id923932984">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Health-Calculator.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Health Calculator For macOS</p>
              <ul className="App-Features">
                <li>Body Mass Index Calculator</li>
                <li>Body Adiposity Index Calculator</li>
                <li>Basal Metabolic Rate Calculator</li>
                <li>Calorie Calculator</li>
                <li>Body Fat Percentage Calculator</li>
                <li>Target Heart Rate Calculator</li>
                <li>Ideal Body Weight Calculator</li>
                <li>Body Surface Area (BSA) Calculator</li>
                <li>Lean Body Mass (LBM) Calculator</li>
                <li>Weight Loss Percentage Calculator</li>
                <li>Waist Hip Ratio Calculator</li>
                <li>Waist Height Ratio Calculator</li>
                <li>Bench Press Calculator</li>
                <li>Cost of Smoking Calculator</li>
                <li>And many more, totalling 34 Calculators</li>
              </ul> 
              <div className="App-Links">
                <a className="App-Link" href="https://loaysetrak.github.io/#macos/health-calculator">About Version 4.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1019029632">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Geometry-Calculator.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Geometry Calculator For macOS</p>
              <ul className="App-Features">
                <li>30 Geometry-Calculators split into 4 categories</li>
                <li>Area</li>
                <li>Surface Area</li>
                <li>Volume</li>
                <li>Perimeter</li>
              </ul> 
              <div className="App-Links">
                <a className="App-Link" href="https://loaysetrak.github.io/#macos/geometry-calculator">About Version 4.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1116628390">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Physics-Calculator.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Physics Calculator For macOS</p>
              <ul className="App-Features">
                <li>120 Physics Calculators</li>
              </ul> 
              <div className="App-Links">
              <a className="App-Link" href="https://loaysetrak.github.io/#macos/physics-calculator">About Version 4.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1104781999">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Finance-Calculator.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Finance Calculator For macOS</p>
              <ul className="App-Features">
                <li>Includes 97 Financial-Calculators split into 5 Categories:</li>
                <li>31 General Finance Calculators</li>
                <li>11 Banking Finance Calculators</li>
                <li>28 Stocks/Bonds Finance Calculators</li>
                <li>25 Corporate Finance Calculators</li>
                <li>2 Financial Markets Calculators</li>
              </ul> 
              <div className="App-Links">
              <a className="App-Link" href="https://loaysetrak.github.io/#macos/finance-calculator">About Version 4.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1130694845">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Engineering-Calculator.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Engineering Calculator For macOS</p>
              <ul className="App-Features">
              <li>Includes 60 Emgineering-Calculators split into 3 Categories:</li>
                <li>29 Civil Engineering Calculators</li>
                <li>18 Electrical Engineering Calculators</li>
                <li>13 Mechanical Engineering Calculators</li>
              </ul> 
              <div className="App-Links">
              <a className="App-Link" href="https://loaysetrak.github.io/#macos/engineering-calculator">About Version 4.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1156440755">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Dynamics-Calculator.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Dynamics Calculator For macOS</p>
              <ul className="App-Features">
                <li>78 Dynamics-Calculators split into 3 categories:</li>
                <li>5 Basic Dynamics Calculators</li>
                <li>24 Astrodynamics Calculators</li>
                <li>49 Thermodynamics Calculators</li>
              </ul> 
              <div className="App-Links">
              <a className="App-Link" href="https://loaysetrak.github.io/#macos/dynamics-calculator">About Version 4.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1225872584">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Chemistry-Calculator.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Chemistry Calculator For macOS</p>
              <ul className="App-Features">
                <li>35 Chemistry Calculators</li>
              </ul> 
              <div className="App-Links">
              <a className="App-Link" href="https://loaysetrak.github.io/#macos/chemistry-calculator">About Version 4.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1149303127">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Fluid-Mechanics-Calculator.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Fluid Mechanics Calculator For macOS</p>
              <ul className="App-Features">
                <li>90 Fluid Mechanics Calculators</li>
              </ul> 
              <div className="App-Links">
              <a className="App-Link" href="https://loaysetrak.github.io/#macos/fluid-mechanics-calculator">About Version 4.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1149304414">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Quick-Notes.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Quick Notes</p>
              <ul className="App-Features">
                <li>Basic and easy to use Notes Widget</li>
              </ul> 
              <div className="App-Links">
              <a className="App-Link" href="https://loaysetrak.github.io/#macos/quick-notes">About Version 1.2</a>
              <a className="App-Link" href="https://apps.apple.com/app/id1260480179">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Strong-Password-Generator.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Strong Password Generator For macOS</p>
              <ul className="App-Features">
                <li>Generate Passwords</li>
                <li>Includes today view Widget</li>
              </ul> 
              <div className="App-Links">
              <a className="App-Link" href="https://loaysetrak.github.io/#macos/strong-password-generator">About Version 1.0.1</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1387740382">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Cipher.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Cipher For macOS</p>
              <ul className="App-Features">
                <li>Encrypt and decrypt text</li>
              </ul> 
              <div className="App-Links">
              <a className="App-Link" href="https://loaysetrak.github.io/#macos/cipher">About Version 1.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1468668353">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Base-Converter-Pro.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Base Converter Pro for macOS</p>
              <ul className="App-Features">
                <li>Get instant conversion between Bases 2-64</li>
              </ul> 
              <div className="App-Links">
              <a className="App-Link" href="https://loaysetrak.github.io/#macos/base-converter-pro">About Version 2.1.1</a>
                <a className="App-Link" href="https://apps.apple.com/app/id939169935">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Color-Code-Generator.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Color Code Generator</p>
              <ul className="App-Features">
                <li>Must have tool for developers</li>
              </ul> 
              <div className="App-Links">
              <a className="App-Link" href="https://loaysetrak.github.io/#macos/color-code-generator">About Version 2.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1478407640">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Falling-Blocks.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Falling Blocks for macOS</p>
              <ul className="App-Features">
                <li>Block puzzle based game for Tetris fans</li>
              </ul> 
              <div className="App-Links">
              <a className="App-Link" href="https://loaysetrak.github.io/#macos/falling-blocks">About Version 1.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1474614556">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Elements.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Elements for macOS</p>
              <ul className="App-Features">
                <li>Periodic Table of Elements</li>
              </ul> 
              <div className="App-Links">
              <a className="App-Link" href="https://loaysetrak.github.io/#macos/elements">About Version 2.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id924555662">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default withRouter(MacOSApps);
