
import React from 'react';
import Footer from '..//Footer'
import Header from '..//Header'
import ImageGallery from '..//ImageGallery'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'

class Chemistry extends React.Component {
  constructor() {
    super();

    this.state = {
      routerValue: Router,
      imageList: ["Chemistry-Calculator1","Chemistry-Calculator2","Chemistry-Calculator3","Chemistry-Calculator4","Chemistry-Calculator5","Chemistry-Calculator6"],
    };
  }

  render() {
    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside-Clear">
          <div className="App-Page-Top">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Chemistry-Calculator.png'} alt=""/>
            <p className="App-Page-Title">Chemistry Calculator</p>
          </div>
          <a className="Download-Icon-Link" href="https://itunes.apple.com/app/id1149303127?mt=12">
            <img className="Download-Icon" src={process.env.PUBLIC_URL + '/DownloadBadges/Download_macOS-Black.png'} alt=""/>
          </a>
          <ImageGallery
          imageList = {this.state.imageList}
          OS = {"macOS/"}
          folder = {"Chemistry-Calculator/"}
          />
          <div className="App-Description">
            <p>Chemistry Calculator is an easy to use App that Contains 35 Calculators</p>
            <p>Chemistry Calculator includes the following Calculators:</p>
            <ul>
              <li>Ideal Gas Law</li>
              <li>Boyle's Law</li>
              <li>Charles Law</li>
              <li>Gay-Lussac's Law</li>
              <li>Combined Gas Law</li>
              <li>Crude Protein Estimation</li>
              <li>Crude Fibre Estimation</li>
              <li>Molar Mass of Gas</li>
              <li>Dilution of Solutions</li>
              <li>Estimation of Calcium by Titration</li>
              <li>Sand Silica Estimation</li>
              <li>Equivalent Weight of Acid</li>
              <li>Neutralization Reaction</li>
              <li>Unknown Metal Weight</li>
              <li>Oxidizing and Reducing Agents</li>
              <li>Henderson Hasselbalch Equation</li>
              <li>Soluble Protein Estimation</li>
              <li>Hydrogen Ion Concentration</li>
              <li>Enthalphy</li>
              <li>Ether Extract Percentage</li>
              <li>Fatty Acid</li>
              <li>Equivalent Mass of Acid</li>
              <li>Double Decomposition</li>
              <li>Equivalent Oxygen Weight</li>
              <li>Molarity</li>
              <li>Avogadro's Number</li>
              <li>Arrhenius Equation</li>
              <li>Atomic Mass</li>
              <li>Molar Heat (or Enthalpy) of Vaporization</li>
              <li>Vapour Density of Gas</li>
              <li>Molecular Mass using GMV Law</li>
              <li>Mass Percent Chemical without Given Masses</li>
              <li>Mass Percent Chemicals with Masses</li>
              <li>Dilution Factor</li>
              <li>Density of Liquid</li>
            </ul>
            <br></br>
            <p>It Also Includes:</p>
            <ul>
              <li>Description and formula for each calculator</li>
              <li>Formula for Each Calculator</li>
              <li>Description for Each Calculator</li>
              <li>Copy Answer Button</li>
              <li>Clear Button</li>
              <li>Clear All Button</li>
            </ul>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default withRouter(Chemistry);
