
import React from 'react';
import Footer from '..//Footer'
import Header from '..//Header'
import ImageGallery from '..//ImageGallery'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'

class Health extends React.Component {
  constructor() {
    super();

    this.state = {
      routerValue: Router,
      imageList: ["Health-Calculator1","Health-Calculator2","Health-Calculator3","Health-Calculator4","Health-Calculator5","Health-Calculator6"],
    };
  }

  render() {
    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside-Clear">
          <div className="App-Page-Top">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Health-Calculator.png'} alt=""/>
            <p className="App-Page-Title">Health Calculator</p>
          </div>
          <a className="Download-Icon-Link" href="https://itunes.apple.com/app/id1019029632?mt=12">
            <img className="Download-Icon" src={process.env.PUBLIC_URL + '/DownloadBadges/Download_macOS-Black.png'} alt=""/>
          </a>
          <ImageGallery
          imageList = {this.state.imageList}
          OS = {"macOS/"}
          folder = {"Health-Calculator/"}
          />
          <div className="App-Description">
            <p>A simple App that includes 34 Health Calculators:</p>
            <ul>
              <li>Body Mass Index (BMI) Calculator</li>
              <li>Body Adiposity Index (BAI) Calculator</li>
              <li>Basal Metabolic Rate (BMR) Calculator</li>
              <li>Resting Metabolic Rate (RMR) Calculator</li>
              <li>Body Fat Percentage Calculator</li>
              <li>Calorie Calculator</li>
              <li>Target Heart Rate (THR) Calculator</li>
              <li>Ideal Body Weight (IBW) Calculator</li>
              <li>Body Surface Area (BSA) Calculator</li>
              <li>Lean Body Mass (LBM) Calculator</li>
              <li>Weight Loss Percentage Calculator</li>
              <li>Human Water Requirement Calculator</li>
              <li>Waist Hip Ratio Calculator</li>
              <li>Waist Height Ratio (WHtR) Calculator</li>
              <li>Bench Press Calculator</li>
              <li>Cost of Smoking Calculator</li>
              <li>Beep Test Calculator</li>
              <li>VO2 Max Calculator</li>
              <li>Heart Rate Calories Burned Calculator</li>
              <li>Heart Rate Calories Burned Known VO2 Calculator</li>
              <li>Blood Volume Calculator</li>
              <li>Estimated Blood Volume Calculator</li>
              <li>Cycling Calories Burned Calculator</li>
              <li>Fat Free Mass Index (FFMI) Calculator</li>
              <li>Drops Per Minute Calculator</li>
              <li>Hyponatremia Correction Calculator</li>
              <li>IV Drip Rate Calculator</li>
              <li>IV Flow Rate Calculator</li>
              <li>nsulin Dosage Level Calculator</li>
              <li>Systematic Vascular Resistance (SVR) Calculator</li>
              <li>Weight Watchers Daily Points Plus Allowance Calculator</li>
              <li>Pro Points Weight Watchers Calculator</li>
              <li>Weight Watchers Points Plus Calculator</li>
              <li>Weight Watchers Old WW Points Calculator</li>
            </ul>
            <br></br>
            <p>It Also Includes:</p>
            <ul>
              <li>Description and formula for each calculator</li>
              <li>Home Button</li>
              <li>Clear Button</li>
              <li>Clear All Button</li>
              <li>Copy Answer Button</li>
            </ul>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default withRouter(Health);
