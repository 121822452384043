
import React from 'react';
import Footer from '..//Footer'
import Header from '..//Header'
import ImageGallery from '..//ImageGallery'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'
import { SegmentedControl } from 'segmented-control'

class CalcVerter extends React.Component {
  constructor() {
    super();

    this.state = {
      routerValue: Router,
      imageList: ["CalcVerter1","CalcVerter2","CalcVerter3","CalcVerter4","CalcVerter5","CalcVerter6","CalcVerter7","CalcVerter8","CalcVerter9","CalcVerter10","CalcVerter11","CalcVerter12","CalcVerter13","CalcVerter14","CalcVerter15","CalcVerter16","CalcVerter17","CalcVerter18","CalcVerter19","CalcVerter20","CalcVerter21","CalcVerter22","CalcVerter23","CalcVerter24","CalcVerter25","CalcVerter26","CalcVerter27"],
      OSName: 'iOS/',
    };
  }

  iOSon() {
    this.setState({ OSName: 'iOS/' })
  }

  iPadOSon() {
    this.setState({ OSName: 'iPadOS/' })
  }

  watchOSon() {
    this.setState({ OSName: 'watchOS/' })
  }

  selectOS(value) {
    if (value === "ios") {
      this.setState({ OSName: 'iOS/' })
    } else if (value === "ipados") {
      this.setState({ OSName: 'iPadOS/' })
    } else if (value === "watchos") {
      this.setState({ OSName: 'watchOS/' })
    }
  }

  render() {
    var OSSelectionButton = [];
    if (this.state.OSName === 'iOS/') {
      OSSelectionButton.push(
        <div className="Toggle-Div">
          <button className="ToggleOS" onClick={() => {this.iPadOSon()}}>View iPadOS</button>
        </div>
      )
    } else if (this.state.OSName === 'iPadOS/') {
      OSSelectionButton.push(
        <div className="Toggle-Div">
          <button className="ToggleOS" onClick={() => {this.watchOSon()}}>View watchOS</button>
        </div>
      ) 
      } else {
        OSSelectionButton.push(
          <div className="Toggle-Div">
            <button className="ToggleOS" onClick={() => {this.iOSon()}}>View iOS</button>
          </div>
        )
    }
    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside-Clear">
          <div className="App-Page-Top">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/CalcVerter.png'} alt=""/>
            <p className="App-Page-Title">CalcVerter</p>
          </div>
          <a className="Download-Icon-Link" href="https://itunes.apple.com/app/id1006610733?mt=8">
            <img className="Download-Icon" src={process.env.PUBLIC_URL + '/DownloadBadges/Download_iOS-Black.png'} alt=""/>
          </a>
          <br></br>
          <br></br>
          <div className="Toggle-Div">
            <SegmentedControl
            name="osSegment"
            className="osSegment"
            options={[
              { label: "iOS", value: "ios", default: true },
              { label: "iPadOS", value: "ipados" },
              { label: "watchOS", value: "watchos"},
            ]}
            setValue={newValue => this.selectOS(newValue)}
            style={{width: 250, color: '#303030'}}
            />
          </div>
          <ImageGallery
          imageList = {this.state.imageList}
          OS = {this.state.OSName}
          folder = {"CalcVerter/"}
          />
          <div className="App-Description">
            <br></br>
            <b><p>App Description:</p></b>
            <p>Welcome to CalcVerter, your all-in-one solution for unit conversion, scientific calculations, and more. CalcVerter is meticulously designed to serve your mathematical and scientific needs comprehensively. Explore its diverse set of calculators and conversion categories below:</p>
            
            <br></br>
            <b><p>Calculator Features:</p></b>
            <p>CalcVerter's calculator is designed for power and flexibility and includes the following features and more:</p>
            <ul>
              <li><b>Multiple Calculator Layouts:</b> Choose from various layouts, including Basic, Programming, and Engineering, to suit your preferences.</li>
              <li><b>Calculator Tape:</b> Review your recent calculations and conversions in the calculator tape.</li>
              <li><b>Customizable Themes:</b> Personalize each calculator's theme to your liking.</li>
              <li><b>Calculator Registers:</b> View and manage calculator registers for advanced calculations.</li>
              <li><b>Undo and Redo:</b> Correct mistakes with ease using the undo and redo functions.</li>
            </ul>

            <br></br>
            <b><p>Unit Conversion Categories:</p></b>
            <p>Choose from 22 different categories including: Acceleration, Angle, Area, Astronomical, Currency, Density, Digital Storage, Energy, Force, Fuel Consumption, Illumination, Inductance, Length, Magnitude, Mass, Power, Pressure, Speed, Temperature, Time Torque, and Volume.</p>

            <br></br>
            <b><p>Constants:</p></b>
            <p>CalcVerter provides access to an extensive collection of constants, organized into various categories including Astronomical, Atomic, CODATA 2018, Electromagnetic, Mathematical, and Physicochemical. These constants offer precise values for your scientific calculations, ensuring accuracy and reliability in your work. Whether you're performing astronomical calculations or complex physics experiments, CalcVerter has the constants you need at your fingertips. Explore and utilize these constants to elevate the precision of your calculations.</p>
            <p>Additionally, CalcVerter empowers you to create your own custom constants. Define and save your unique constants, making them readily available for your calculations. With this feature, you can tailor CalcVerter to suit your specific scientific and mathematical needs.</p>
            <p>Explore and utilize these constants, both predefined and user-created, to elevate the precision of your calculations.</p>

            <br></br>
            <b><p>Specialized Calculators:</p></b>
            <p>In addition to unit conversions, CalcVerter offers specialized calculators to cater to your diverse needs:</p>

            <ul>
              <li><b>Geometry Calculators:</b> Access 30 geometry calculators categorized by Area, Surface Area, Volume, and Perimeter, complete with formulas and descriptions.</li>
              <li><b>Financial Calculators:</b> Make informed financial decisions with 97 calculators, including General, Banking, Stocks/Bonds, Corporate, and Financial Markets, each with detailed formulas and explanations.</li>
              <li><b>Physics Calculators:</b> Empower your scientific endeavors with 120 physics calculators, each featuring comprehensive formulas and descriptions.</li>
              <li><b>Engineering Calculators:</b>  Solve engineering problems with 59 calculators, categorized into Civil, Electrical, and Mechanical, each with detailed formulas and explanations.</li>
              <li><b>Chemistry Calculators:</b> Master chemistry with 35 specialized calculators, each featuring detailed formulas and descriptions.</li>
              <li><b>Fluid Mechanics Calculators:</b> Dive into fluid mechanics with 90 calculators, each featuring detailed formulas and descriptions.</li>
              <li><b>Health Calculators:</b> Stay informed about your health with 34 calculators, each with detailed formulas and descriptions.</li>
              <li><b>Dynamics Calculators</b> Explore dynamics with 78 calculators, categorized into Basic Dynamics, Astrodynamics, and Thermodynamics, each with formulas and explanations.</li>
              <li><b>Quantum Calculators:</b> Get quantum-savvy with 5 calculators, each equipped with formulas and explanations.</li>
              <li><b>User Calculators:</b> Create custom calculators from equations and input the required variables into each field for quick calculations. Share your created calculators with other users and import theirs.</li>
            </ul>

            <br></br>
            <b><p>Customizable Home Screen:</p></b>
            <p>The CalcVerter Home Screen offers an intuitive experience with modules that you can customize according to your preferences:</p>

            <ul>
              <li><b>Search Functionality:</b> Quickly find any calculator, converter, element, or planet with the integrated search functionality.</li>
              <li><b>Unit Converter Module:</b> Easily convert between units across 22 different categories for seamless conversions.</li>
              <li><b>Calculator Module:</b> Perform general calculations using the Calculator module.</li>
              <li><b>Shortcuts Module:</b> Access a list of calculator categories for easy navigation</li>
              <li><b>Customize Modules:</b> Show, hide, or reorganize Home Screen modules based on your preference. Tailor CalcVerter to meet your specific needs.</li>
            </ul>

            <br></br>
            <b><p>Additional Tools:</p></b>
            <p>Discover these unique tools in CalcVerter:</p>

            <ul>
              <li><b>Periodic Table:</b> Access a comprehensive table of elements for quick reference.</li>
              <li><b>Base Converter:</b> Convert between bases 2-64 with ease.</li>
              <li><b>Weight on Other Planets:</b> Calculate your weight on various planets and celestial objects, and explore each planet's details.</li>
              <li><b>Currency Conversion Widget:</b> Easily convert between currencies with a convenient currency conversion widget.</li>
            </ul>
            
            <br></br>
            <b><p>Settings:</p></b>
            <p>CalcVerter offers comprehensive settings customization, empowering you to tailor your app experience to your preferences:</p>
            <ul>
              <li><b>Number Formatters:</b> Customize number formatting for calculations and conversions. Set preferences such as thousands separators, thousands separators style, decimal places, and trailing zeros. Achieve the exact presentation you desire for your numerical results.</li>
              <li><b>App Themes:</b> Personalize the app's visual appearance to match your style. Choose background colors, tint colors, and even app icon options. Make CalcVerter uniquely yours with a theme that suits your taste and enhances your user experience.</li>
              <li><b>Search Filters:</b> Enable or disable specific categories in the search functionality. Tailor your search results to focus on the calculators and converters most relevant to your current needs. Streamline your access to the tools that matter to you.</li>
            </ul>
            <p>With CalcVerter's robust settings, you have control over how you view and interact with the app. Adjust these settings to optimize your mathematical and scientific endeavors.</p>
            <p>Elevate your mathematical and scientific endeavors with CalcVerter today. Download now and experience the ultimate calculator and unit converter app.</p>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default withRouter(CalcVerter);
