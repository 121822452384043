/*THis componenet renders vanilla header with logo and links 

Drop down menu for small screen devices uses this example
https://blog.campvanilla.com/reactjs-dropdown-menus-b6e06ae3a8fe
and is modified
*/

import React from 'react';
import Footer from './Footer'
import Header from './Header'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'

class IOSApps extends React.Component {
  constructor() {
    super();

    this.state = {
      showMenu: false,
      routerValue : Router,
    };
  }

  render() {
    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside">
          <div className="App-Main">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/CalcVerter.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">CalcVerter for iOS, iPadOS and watchOS</p>
              <ul className="App-Features">
                <li>22 Unit Conversion Categories</li>
                <li>Scientific-Calculator with optional RPN mode</li>
                <li>30 Geometry Calculators</li>
                <li>97 Finance Calculators</li>
                <li>120 Physics Calculators</li>
                <li>34 Health Calculators</li>
                <li>59 Engineering Calculators</li>
                <li>78 Dynamics Calculators</li>
                <li>35 Chemistry Calculators</li>
                <li>90 Fluid Mechanics Calculators</li>
                <li>5 Quantum Calculators</li>
                <li>Base Converter Converts between bases 2 - 64</li>
                <li>Weight on other Planets and Celestial Objects Calculator</li>
                <li>Periodic Table Of Elements</li>
                <li>Create, share and import user calculators</li>
                <li>Create, share and import user constants</li>
              </ul> 
              <div className="App-Links">
                <a className="App-Link" href="https://loaysetrak.github.io/#ios/calcverter">About Version 6.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1006610733">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Health-Calculator.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Health Calculator for iOS and iPadOS</p>
              <ul className="App-Features">
                <li>Body Mass Index Calculator</li>
                <li>Body Adiposity Index Calculator</li>
                <li>Basal Metabolic Rate Calculator</li>
                <li>Calorie Calculator</li>
                <li>Body Fat Percentage Calculator</li>
                <li>Target Heart Rate Calculator</li>
                <li>Ideal Body Weight Calculator</li>
                <li>Body Surface Area (BSA) Calculator</li>
                <li>Lean Body Mass (LBM) Calculator</li>
                <li>Weight Loss Percentage Calculator</li>
                <li>Waist Hip Ratio Calculator</li>
                <li>Waist Height Ratio Calculator</li>
                <li>Bench Press Calculator</li>
                <li>Cost of Smoking Calculator</li>
                <li>And many more, totalling 34 Calculators</li>
              </ul> 
              <div className="App-Links">
                <a className="App-Link" href="https://loaysetrak.github.io/#ios/health-calculator">About Version 4.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1038950650">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Geometry-Calculator.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Geometry Calculator for iOS and iPadOS</p>
              <ul className="App-Features">
                <li>30 Geometry-Calculators split into 4 categories</li>
                <li>Area</li>
                <li>Surface Area</li>
                <li>Volume</li>
                <li>Perimeter</li>
              </ul> 
              <div className="App-Links">
                <a className="App-Link" href="https://loaysetrak.github.io/#ios/geometry-calculator">About Version 4.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1286515380">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Physics-Calculator.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Physics Calculator for iOS and iPadOS</p>
              <ul className="App-Features">
                <li>120 Physics Calculators</li>
              </ul> 
              <div className="App-Links">
                <a className="App-Link" href="https://loaysetrak.github.io/#ios/physics-calculator">About Version 4.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1297962147">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Finance-Calculator.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Finance Calculator for iOS and iPadOS</p>
              <ul className="App-Features">
                <li>Includes 97 Financial-Calculators split into 5 Categories:</li>
                <li>31 General Finance Calculators</li>
                <li>11 Banking Finance Calculators</li>
                <li>28 Stocks/Bonds Finance Calculators</li>
                <li>25 Corporate Finance Calculators</li>
                <li>2 Financial Markets Calculators</li>
              </ul> 
              <div className="App-Links">
                <a className="App-Link" href="https://loaysetrak.github.io/#ios/finance-calculator">About Version 4.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1297956111">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Dynamics-Calculator.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Dynamics Calculator For macOS</p>
              <ul className="App-Features">
                <li>78 Dynamics-Calculators split into 3 categories:</li>
                <li>5 Basic Dynamics Calculators</li>
                <li>24 Astrodynamics Calculators</li>
                <li>49 Thermodynamics Calculators</li>
              </ul> 
              <div className="App-Links">
              <a className="App-Link" href="https://loaysetrak.github.io/#ios/dynamics-calculator">About Version 4.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1297961471">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Fluid-Mechanics-Calculator.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Fluid Mechanics Calculator for iOS and iPadOS</p>
              <ul className="App-Features">
                <li>90 Fluid Mechanics Calculators</li>
              </ul> 
              <div className="App-Links">
                <a className="App-Link" href="https://loaysetrak.github.io/#ios/fluid-mechanics-calculator">About Version 4.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1297964597">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Engineering-Calculator.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Engineering Calculator for iOS and iPadOS</p>
              <ul className="App-Features">
              <li>Includes 60 Emgineering-Calculators split into 3 Categories:</li>
                <li>29 Civil Engineering Calculators</li>
                <li>18 Electrical Engineering Calculators</li>
                <li>13 Mechanical Engineering Calculators</li>
              </ul> 
              <div className="App-Links">
                <a className="App-Link" href="https://loaysetrak.github.io/#ios/engineering-calculator">About Version 4.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1297957037">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Chemistry-Calculator.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Chemistry Calculator for iOS and iPadOS</p>
              <ul className="App-Features">
                <li>35 Chemistry Calculators</li>
              </ul> 
              <div className="App-Links">
                <a className="App-Link" href="https://loaysetrak.github.io/#ios/chemistry-calculator">About Version 4.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1297961142">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Strong-Password-Generator.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Strong Password Generator for iOS and iPadOS</p>
              <ul className="App-Features">
                <li>Generate Passwords</li>
                <li>Save Passwords</li>
              </ul> 
              <div className="App-Links">
              <a className="App-Link" href="https://loaysetrak.github.io/#ios/strong-password-generator">About Version 1.0.1</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1468302178">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Cipher.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Cipher iOS and iPadOS</p>
              <ul className="App-Features">
                <li>Encrypt and decrypt text</li>
                <li>Includes Keyboard extension to send encrypted messages to friends</li>
              </ul> 
              <div className="App-Links">
              <a className="App-Link" href="https://loaysetrak.github.io/#ios/cipher">About Version 1.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1468669089">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Base-Converter-Pro.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Base Converter Pro for iOS and iPadOS</p>
              <ul className="App-Features">
                <li>Get instant conversion between Bases 2-36</li>
              </ul> 
              <div className="App-Links">
                <a className="App-Link" href="https://loaysetrak.github.io/#ios/base-converter-pro">About Version 2.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1039218498">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Tetris.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Falling Blocks for iOS, iPadOS, tvOS and watchOS</p>
              <ul className="App-Features">
                <li>Block puzzle based game for Tetris fans</li>
              </ul> 
              <div className="App-Links">
                <a className="App-Link" href="https://loaysetrak.github.io/#ios/tetris">About Version 1.3</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1474442643">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/100-Blocks.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">100 Blocls for iOS and iPadOS</p>
              <ul className="App-Features">
                <li>Highly addictive block puzzle game that helps improve your logic skills and keeps your brain healty.</li>
              </ul> 
              <div className="App-Links">
                <a className="App-Link" href="https://loaysetrak.github.io/#ios/100-blocks">About Version 1.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1507391134">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Modern-Sudoku.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Modern Sudoku for iOS and iPadOS</p>
              <ul className="App-Features">
                <li>Brain training numbers game</li>
              </ul> 
              <div className="App-Links">
                <a className="App-Link" href="https://loaysetrak.github.io/#ios/modern-sudoku">About Version 1.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1604133932">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Crossword-Decipher.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Crossword Decipher for iOS and iPadOS</p>
              <ul className="App-Features">
                <li>Codeword game to help you have fun, keep your brain fit and Improve your literacy and logic skills</li>
              </ul> 
              <div className="App-Links">
                <a className="App-Link" href="https://loaysetrak.github.io/#ios/crossword-decipher">About Version 1.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1470579852">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Speedy.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Speedy for iOS and iPadOS</p>
              <ul className="App-Features">
                <li>Speed and reflex based game</li>
              </ul> 
              <div className="App-Links">
                <a className="App-Link" href="https://loaysetrak.github.io/#ios/speedy">About Version 1.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1472518879">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Elements.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Elements for iOS and iPadOS</p>
              <ul className="App-Features">
                <li>Periodic Table of Elements</li>
              </ul> 
              <div className="App-Links">
              <a className="App-Link" href="https://loaysetrak.github.io/#macos/elements">About Version 2.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id924555662">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Vault.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Vault for iOS and iPadOS</p>
              <ul className="App-Features">
                <li>Secretly store data behind a calculator</li>
                <li>Can store Photos, Videos, Audio Recordings, Notes, Checklists, Websites</li>
              </ul> 
              <div className="App-Links">
              <a className="App-Link" href="https://loaysetrak.github.io/#macos/vault">About Version 1.1</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1543529533">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="App-Main">
          <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Color-Code-Generator.png'} alt=""/>
            <div className="App-Info">
              <p className="App-Title">Color Code Generator</p>
              <ul className="App-Features">
                <li>Must have tool for developers</li>
              </ul> 
              <div className="App-Links">
              <a className="App-Link" href="https://loaysetrak.github.io/#ios/color-code-generator">About Version 2.0</a>
                <a className="App-Link" href="https://apps.apple.com/app/id1478407640">Get Now</a>
                <a className="App-Link" href="https://loaysetrak.github.io/#contact">Support</a>
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default withRouter(IOSApps)
